<template>
  <div class="dropdown" @click="dropdownOpen = !dropdownOpen">
    <div class="dropbtn">
      <button>MODES</button>
      <img class="triangle-icon" :src="triangleIcon" alt="" />
    </div>
    <div class="dropdown-content" v-show="dropdownOpen">
      <a
        v-for="(mode, index) in availableModes"
        :key="index"
        :href="mode.disabled ? null : '#'"
        @click.prevent="mode.disabled || setMode(mode.modeName)"
        :class="{ disabled: mode.disabled }"
      >
        <img class="mode-icon" :src="mode.icon" alt="" />
        <span class="mode-text">{{ mode.modeName }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import telestrationsIcon from "@/assets/telestration.svg";
import tagsIcon from "@/assets/tags.svg";
import measurementIcon from "@/assets/measurement.svg";
import triangleIcon from "@/assets/triangle.svg";

export default defineComponent({
  props: {
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      availableModes: [
        { modeName: "Telestration", icon: telestrationsIcon, disabled: false },
        { modeName: "Tags", icon: tagsIcon, disabled: false }, // this mode is disabled
        { modeName: "Measurement", icon: measurementIcon, disabled: true },
      ],
      triangleIcon: triangleIcon,
      dropdownOpen: false,
    };
  },
  methods: {
    setMode(newMode) {
      //console.log(newMode);
      this.$emit("updateMode", newMode);
    },
  },
});
</script>

<style>
.dropdown {
  display: block;
}
.dropbtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(63, 63, 63);
  padding: 0.5rem 0.25rem;
  border-radius: 0.5rem;
  border: 0.1rem solid #858585;
  gap: 5rem;
}
.dropbtn button {
  background-color: rgb(63, 63, 63);
  color: white;
  font-weight: bold;
  font-size: 0.75rem;
  border: none;
  cursor: pointer;
  align-self: flex-end;
}
.triangle-icon {
  width: 0.7rem;
  height: 0.7rem;
}
.dropdown-content {
  position: absolute;
  background-color: black;
  z-index: 1;
}

.dropdown-content .mode-icon {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
}

.dropdown-content .mode-text {
  display: inline-block;
  font-weight: bold;
  font-size: 0.8rem;
}

.dropdown-content a {
  display: flex;
  align-items: center;
  color: white;
  padding: 0.5rem 0.5rem;
  padding-right: 1rem;
  text-decoration: none;
  cursor: pointer;
}

.dropdown-content a:hover {
  background-color: #fef3e9;
}

/* New CSS rule to fix the hover effect */
.dropbtn:hover + .dropdown-content {
  display: block;
}
.dropdown-content a.disabled {
  color: #3f3f3f;
  pointer-events: none; /* make it unclickable */
}
.dropdown-content a.disabled .mode-icon {
  filter: grayscale(100%);
}
</style>
