<template>
  <div class="image-drawer">
    <canvas
      ref="canvas"
      @mousedown="startDrawing"
      @touchstart="startDrawing"
      @mousemove="draw"
      @touchmove="draw"
      @mouseup="stopDrawing"
      @touchend="stopDrawing"
    ></canvas>
  </div>
</template>

<script>
import myImage from "@/assets/12_20_13_269.jpg";
import { mapGetters } from "vuex";
import { watch } from "@vue/runtime-core";
export default {
  props: {
    mode: {
      type: String,
      required: true,
    },
    lineColor: {
      type: String,
      required: true,
    },
    lines: {
      type: Array,
      required: true,
    },
    imageData: {
      type: Blob,
      default: null,
    },
  },
  data() {
    return {
      canvas: null,
      context: null,
      drawing: false,
      image: null,
      imageUrl: myImage,
    };
  },
  async mounted() {
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds
    this.initCanvas();

    watch(
      () => this.imageData,
      () => {
        this.initCanvas();
      }
    );
  },
  computed: {
    ...mapGetters(["isuPath", "isuToken", "isuAuth"]),
    lastPoint() {
      if (
        this.lines.length === 0 ||
        this.lines[this.lines.length - 1].points.length === 0
      ) {
        return null;
      }
      return this.lines[this.lines.length - 1].points[
        this.lines[this.lines.length - 1].points.length - 1
      ];
    },
  },
  methods: {
    initCanvas() {
      this.canvas = this.$refs.canvas;
      this.context = this.canvas.getContext("2d");

      const root = document.documentElement;
      const maxWidthRatio = 0.93;

      const calculateDimensions = (width, height) => {
        const widthScaleFactor = (root.clientWidth * maxWidthRatio) / width;
        const originalAspectRatio = width / height;

        let newWidth, newHeight;
        newWidth = width * widthScaleFactor;
        newHeight = newWidth / originalAspectRatio;

        return { newWidth, newHeight };
      };
      this.image = new Image();
      if (this.imageData === null) {
        this.image.src = this.imageUrl;
      } else {
        this.image.src = URL.createObjectURL(this.imageData);
      }
      this.image.onload = () => {
        const { newWidth, newHeight } = calculateDimensions(
          this.image.width,
          this.image.height
        );
        this.canvas.width = newWidth;
        this.canvas.height = newHeight;
        this.context.drawImage(this.image, 0, 0, newWidth, newHeight);
      };
    },
    updateMousePos(event) {
      const rect = this.canvas.getBoundingClientRect();
      const scaleX = this.canvas.width / rect.width;
      const scaleY = this.canvas.height / rect.height;

      const x = (event.clientX - rect.left) * scaleX;
      const y = (event.clientY - rect.top) * scaleY;
      this.$emit("updateMousePos", { x: x, y: y });
    },
    startDrawing(event) {
      event.preventDefault();
      if (event.type === "touchstart") {
        event = event.touches[0];
      }
      if (this.mode === "Telestration") {
        this.drawing = true;
        this.context.beginPath();
        this.context.strokeStyle = this.lineColor;
      }
      this.$emit("startDrawing", {
        srcWidth: this.canvas.width,
        srcHeight: this.canvas.height,
      });
      this.updateMousePos(event);
      if (this.lastPoint) {
        this.context.moveTo(this.lastPoint.x, this.lastPoint.y);
      }
    },
    draw(event) {
      if (this.mode !== "Telestration") return;
      event.preventDefault();
      if (event.type === "touchmove") {
        event = event.touches[0];
      }
      if (!this.drawing || !this.lastPoint) return;
      this.updateMousePos(event);
      this.context.lineTo(this.lastPoint.x, this.lastPoint.y);
      this.context.stroke();
    },
    stopDrawing(event) {
      if (event.type === "touchend" || event.type === "touchcancel") {
        event = event.changedTouches[0];
      }
      if (this.mode === "Telestration") {
        this.drawing = false;
        this.context.closePath();
      }
      this.$emit("stopDrawing");
    },
  },
};
</script>

<style>
.image-drawer {
  display: inline-block;
  position: relative;
}

canvas {
  border: none;
  border-radius: 1rem;
}
</style>
