<template>
  <div class="telestrations-control">
    <div class="button-container">
      <button
        class="orange-btn"
        @click="emitEvent('refresh')"
        :disabled="autoRefresh"
      >
        Refresh
      </button>
      <button
        class="orange-btn"
        @click="emitEvent('autoRefresh')"
        :class="{ pressed: this.autoRefresh }"
      >
        Auto Refresh
      </button>
      <button
        v-if="mode === 'Tags'"
        class="orange-btn"
        @click="emitEvent('add')"
        :class="{ pressed: this.tagsSubMode === 'add' }"
      >
        Add
      </button>
      <button
        v-if="mode === 'Tags'"
        class="orange-btn"
        @click="emitEvent('delete')"
        :class="{ pressed: this.tagsSubMode === 'delete' }"
      >
        Delete
      </button>
      <button
        v-if="mode === 'Telestration'"
        class="orange-btn"
        @click="emitEvent('undo')"
      >
        Undo
      </button>
      <button class="orange-btn" @click="emitEvent('clearAll')">
        Clear All
      </button>
    </div>
    <div class="color-picker" v-if="mode === 'Telestration'">
      <p>Select color:</p>
      <div
        v-for="(color, index) in colors"
        :key="index"
        class="color-btn"
        :style="{ backgroundColor: color.value }"
        @click="selectColor(color.value)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TelestrationsControl",
  props: {
    lineColor: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    tagsSubMode: {
      type: String,
      required: true,
    },
    autoRefresh: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      colors: [
        { name: "black", value: "#000000" },
        { name: "white", value: "#FFFFFF" },
        { name: "magenta", value: "#FF00FF" },
        { name: "cyan", value: "#00FFFF" },
        { name: "yellow", value: "#FFFF00" },
        { name: "green", value: "#27FF71" },
      ],
    };
  },
  methods: {
    emitEvent(eventName) {
      this.$emit(eventName);
    },
    selectColor(color) {
      console.log(color);
      this.$emit("colorSelected", color);
    },
  },
};
</script>

<style>
.telestrations-control {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0.5rem;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-right: 0.8rem;
}

.orange-btn {
  flex: 1;
  height: 2rem;
  width: 6.3rem;
  background-color: #f48120;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: bold;
  margin: 0 0.5rem;
  padding: 0.625rem 1.25rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 0.8rem;
  transition-duration: 0.4s;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 2px rgba(255, 165, 0, 0.5);
}

.orange-btn:disabled {
  background-color: #888888;
  cursor: not-allowed;
  box-shadow: none;
}

.orange-btn:hover:not(:disabled) {
  background-color: darkorange;
}
.pressed {
  /* background-color: rgb(255, 135, 0) !important; */
  transform: scale(0.97);
  top: 2px;
  left: 2px;
  box-shadow: none;
}
.color-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.color-picker p {
  color: white;
  padding: 0.2rem;
  margin: 0rem;
  font-size: 0.8rem;
  font-weight: bold;
  white-space: nowrap;
}

.colors-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.color-btn {
  width: 1.75rem;
  height: 1.75rem;
  margin: 0.3rem;
  border-radius: 0.6rem;
  cursor: pointer;
  border: 0.05rem solid black;
}
</style>
