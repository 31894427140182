<template>
  <div
    class="control-container"
    :class="{ 'control-container-collapsed': !modeDisplay }"
  >
    <div class="controls" v-if="modeDisplay">
      <TelestrationsControl
        :line-color="lineColor"
        :mode="mode"
        :autoRefresh="autoRefresh"
        :tags-sub-mode="tagsSubMode"
        @add="setTagsMode('add')"
        @delete="setTagsMode('delete')"
        @refresh="refresh"
        @undo="undo"
        @clearAll="clearAll"
        @colorSelected="setColor"
        @autoRefresh="upadateAutoRefresh"
      ></TelestrationsControl>
      <div class="fixed-control">
        <ModeControl @updateMode="setMode" :mode="mode"></ModeControl>
        <SettingControl @updateSetting="setSetting"></SettingControl>
      </div>
    </div>
    <div class="control-btn">
      <button @click="setControlDisplay">
        <img
          class="control-btn-icon"
          :class="{ 'rotate-icon': modeDisplay }"
          :src="arrow"
          alt=""
        />
      </button>
    </div>
  </div>
  <SurgeonCanvas
    :line-color="lineColor"
    :mode="mode"
    :lines="lines"
    :imageData="imageData"
    @startDrawing="newLine"
    @updateMousePos="updateUserDrawing"
    @stopDrawing="handleFinishedDrawing"
  ></SurgeonCanvas>
  <div class="error-icon" v-if="errorState">
    <img :src="errorImg" alt="" />
  </div>
</template>

<script>
import SurgeonCanvas from "@/components/SurgeonCanvas.vue";
import ModeControl from "@/components/ModeControl.vue";
import TelestrationsControl from "@/components/TelestrationsControl.vue";
import SettingControl from "@/components/SettingControl.vue";
import arrow from "@/assets/arrow.svg";
import error from "@/assets/error.svg";
import { mapGetters } from "vuex";

export default {
  components: {
    SurgeonCanvas,
    ModeControl,
    TelestrationsControl,
    SettingControl,
  },
  emits: ["update-header"],
  beforeRouteLeave(to, from, next) {
    this.$emit("update-header", {
      title: "",
      headerImage: "",
    });
    next();
  },
  data() {
    return {
      mode: "Telestration",
      lineColor: "#FFFFFF", //white
      tagsSubMode: "add",
      imageData: null,
      arrow: arrow,
      lines: [],
      tags: [],
      errorImg: error,
      errorState: false,
      modeDisplay: false,
      inOperation: false,
      autoRefresh: false,
    };
  },
  watch: {
    errorState(newValue) {
      if (newValue) {
        clearTimeout(this.errorTimeoutId); // Clear the previous timer, if any
        this.errorTimeoutId = setTimeout(() => {
          this.errorState = false; // Reset the errorState to its default value
        }, 15000);
      }
    },
  },
  async mounted() {
    this.updateHeader();
    this.fetchImage();
  },
  computed: {
    ...mapGetters(["isuPath", "isuToken", "isuAuth"]),
  },
  methods: {
    setMode(newMode) {
      this.mode = newMode;
    },
    upadateAutoRefresh() {
      this.autoRefresh = !this.autoRefresh;
      if (this.autoRefresh) {
        this.refreshIntervalId = setInterval(async () => {
          await this.fetchImage();
        }, 1000 * 0.5);
      } else {
        clearInterval(this.refreshIntervalId);
      }
    },
    setSetting(newSetting) {
      if (newSetting === "TestPage") {
        this.$router.push("/test");
      } else if (newSetting === "Logout") {
        this.$router.push("/");
      }
    },
    updateHeader() {
      this.$emit("update-header", {
        title: this.mode,
        headerImage: "",
      });
    },
    setColor(color) {
      this.lineColor = color;
    },
    setTagsMode(newSubMode) {
      this.tagsSubMode = newSubMode;
    },
    setControlDisplay() {
      this.modeDisplay = !this.modeDisplay;
    },
    refresh() {
      this.fetchImage();
    },
    undo() {
      this.lines.pop();
      this.sendDelete("line");
      this.fetchImage();
    },
    clearAll() {
      this.lines = [];
      if (this.mode === "Telestration") {
        this.sendDelete("lines");
      } else {
        this.sendDelete("tags");
      }
      this.fetchImage();
    },
    newLine(payload) {
      this.inOperation = true;
      if (this.mode === "Telestration") {
        this.lines.push({
          points: [],
          color: this.lineColor,
          srcWidth: payload.srcWidth,
          srcHeight: payload.srcHeight,
        });
      } else if (this.mode === "Tags" && this.tagsSubMode == "add") {
        this.tags.push({
          tag: {},
          srcWidth: payload.srcWidth,
          srcHeight: payload.srcHeight,
        });
      }
    },
    updateUserDrawing(payload) {
      const x = payload.x;
      const y = payload.y;
      if (this.mode === "Telestration") {
        this.lines[this.lines.length - 1].points.push({ x, y });
      } else if (this.mode === "Tags" && this.tagsSubMode == "add") {
        this.tags[this.tags.length - 1].tag = { x: x, y: y };
      }
    },
    handleFinishedDrawing() {
      this.inOperation = false;
      if (this.mode === "Telestration") {
        this.sendIsuData();
      } else if (this.mode === "Tags") {
        if (this.tagsSubMode === "add") {
          this.sendIsuData();
        } else {
          if (!this.tags.length) {
            alert("no tags to delete!");
          } else {
            this.sendDelete("tag");
          }
        }
      }
    },
    async fetchImage() {
      if (this.inOperation) {
        return; // Exit the function if inOperation is true
      }
      try {
        const options = {
          headers: {
            authorization: this.isuAuth,
            "x-auth-token": this.isuToken,
          },
        };
        this.inOperation = true;
        const response = await fetch(`${this.isuPath}/snapshot`, options);
        if (response.ok) {
          console.log("got the img");
          this.imageData = await response.blob();
        } else {
          console.log("problem with getting an img");
          this.errorState = true;
        }
      } catch (error) {
        console.log(error);
        this.errorState = true;
      }
      this.inOperation = false;
    },
    async sendIsuData() {
      let url;
      let data;
      const authHeader = {
        "Content-Type": "application/json",
        authorization: this.isuAuth,
        "x-auth-token": this.isuToken,
      };
      if (this.mode === "Telestration") {
        url = `${this.isuPath}/telestration/line`;
        data = this.lines[this.lines.length - 1];
      } else if (this.mode === "Tags") {
        url = `${this.isuPath}/tag`;
        data = this.tags[this.tags.length - 1];
      } else {
        return;
      }
      try {
        const options = {
          method: "POST",
          headers: authHeader,
          body: JSON.stringify(data),
        };
        const response = await fetch(url, options);

        if (response.ok) {
          console.log("successful in sending data to isu");
        } else {
          console.log("problem with sending data to isu");
          this.errorState = true;
        }
      } catch (error) {
        console.log(error);
        this.errorState = true;
      }
    },
    async sendDelete(deleteType) {
      let url;
      const options = {
        headers: {
          authorization: this.isuAuth,
          "x-auth-token": this.isuToken,
        },
        method: "DELETE",
      };

      if (this.mode === "Telestration") {
        url = `${this.isuPath}/telestration/${deleteType}`;
      } else if (this.mode === "Tags") {
        url = `${this.isuPath}/${deleteType}`;
        options.body = JSON.stringify(this.tags[this.tags.length - 1]);
      } else {
        return;
      }

      try {
        const response = await fetch(url, options);
        if (response.ok) {
          console.log(`Delete was successful: +  ${deleteType}`);
          if (this.mode == "Tags") {
            this.tags.pop();
          }
        } else {
          console.log("problem with delete");
          this.errorState = true;
        }
      } catch (error) {
        console.log(error);
        this.errorState = true;
      }
    },
  },
};
</script>

<style>
.control-container {
  position: absolute;
  top: 10vh;
  left: auto;
  right: auto;
  margin: auto;
  display: flex;
  flex-direction: row;
  background-color: rgba(78, 68, 67, 0.5);
  z-index: 1;
  border-radius: 1rem;
  transition: transform 0.3s ease-in-out;
}
.control-container-collapsed {
  left: 5vw;
}
.controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.fixed-control {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border-left: 1.5px solid #26a1a1;
  border-right: 1.5px solid #26a1a1;
  gap: 1rem;
}
.control-btn {
  padding: 1rem;
  align-self: center;
}
.control-btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.control-btn-icon {
  background-color: transparent;
  width: 1rem;
  height: 1rem;
}
.rotate-icon {
  transform: rotate(180deg);
}
.error-icon {
  position: absolute;
  top: 75%;
  right: 88%;
}
.error-icon img {
  width: 2.2rem;
  height: 2.2rem;
}
</style>
