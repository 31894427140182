<template>
  <header>
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap"
      rel="stylesheet"
    />
  </header>
  <main>
    <div class="black-bg"></div>
    <div class="page-header">
      <img v-if="headerImage" :src="headerImage" alt="Header Image" />
      <h1 v-if="title">{{ title }}</h1>
    </div>
    <router-view v-on:update-header="updateHeader" />
    <div class="snackbar-container">
      <div class="snackbar" v-if="updateExists">
        <span class="message">An update is available</span>
        <button class="update-button" @click="refreshApp">Update</button>
      </div>
    </div>
  </main>
</template>

<script>
import update from "./mixins/update";

export default {
  data() {
    return {
      title: "",
      headerImage: "",
    };
  },
  mixins: [update],
  methods: {
    updateHeader(data) {
      this.title = data.title;
      this.headerImage = data.headerImage;
    },
  },
};
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

#app {
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: relative;
  font-family: "Inter", sans-serif;
}

.black-bg {
  background-color: black;
  z-index: -3;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
}

#app::after {
  content: "";
  position: absolute;
  border: 2px solid rgb(0, 64, 65);
  pointer-events: none;
  z-index: -2;
  border-radius: 1.2rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#app::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("@/assets/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
  z-index: 0;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: -1;
  background-color: black;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.page-header img {
  height: 2.5rem;
  z-index: 1;
  padding-top: 0.3rem;
}

.page-header h1 {
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  z-index: 1;
  margin: 0;
  padding-top: 0.3rem;
  padding-bottom: 0.7rem;
}

.snackbar-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999;
}

.snackbar {
  display: flex;
  align-items: center;
  background-color: #2196f3;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  transition: opacity 0.3s, transform 0.3s;
}

.message {
  flex-grow: 1;
  margin-right: 10px;
}

.update-button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
}
</style>
