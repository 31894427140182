import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SurgeonView from "../views/SurgeonView.vue";
import TestViewVue from "@/views/TestView.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: "ISU App" }, // Set the title for the Home route
  },
  {
    path: "/surgeon",
    name: "surgeon-view",
    component: SurgeonView,
    meta: { title: "ISU App" }, // Set the title for the Home route
  },
  {
    path: "/test",
    name: "test-view",
    component: TestViewVue,
    meta: { title: "ISU App" }, // Set the title for the Home route
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title; // Update the tab title with the meta title of the route
  next();
});

export default router;
