<template>
  <div class="test">
    <button @click="testISU">Test ISU</button>
    <p>{{ result }}</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      result: "Click the button to test the ISU",
    };
  },
  computed: {
    ...mapGetters(["isuPath", "isuToken", "isuAuth"]),
  },
  methods: {
    async testISU() {
      try {
        const min = 1;
        const max = 100;
        const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        const randomNum2 = Math.floor(Math.random() * (max - min + 1)) + min;
        const body = { a: randomNum, b: randomNum2 };
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: this.isuAuth,
            "x-auth-token": this.isuToken,
          },
          body: JSON.stringify(body),
        };
        const response = await fetch(`${this.isuPath}/samplerequest`, options);
        const data = await response.json();
        if (response.ok) {
          console.log(data);
          this.result = `a is equal to ${body.a}, b is equal to ${body.b}, and res is equal to ${data.res}.`;
        } else {
          this.result = "Error";
        }
      } catch (error) {
        this.result = "Error, something went wrong";
      }
    },
  },
};
</script>

<style>
.test {
  display: flex;
  flex-direction: column;
  padding-top: 15rem;
}
.test button {
  max-width: 5rem;
}
.test p {
  color: white;
}
</style>
