import Vuex from "vuex";

export default new Vuex.Store({
  state: {
    isuAuth: "WtW3bcFBB9gvwmDQ4jYhGGOPKwFP0ikK5WdAkWGNRCI29PDDyPDSIiuFnK2GAY95",
    isuPath: "https://10.0.0.10",
    isuToken: "",
  },
  mutations: {
    SET_ISU_TOKEN(state, payload) {
      state.isuToken = payload;
    },
    SET_ISU_PATH(state, payload) {
      state.isuPath = payload;
    },
  },
  actions: {
    async setIsuToken(context, payload) {
      context.commit("SET_ISU_TOKEN", payload);
    },
    async setIsuPath(context, payload) {
      context.commit("SET_ISU_PATH", payload);
    },
  },
  getters: {
    isuPath: (state) => state.isuPath,
    isuToken: (state) => state.isuToken,
    isuAuth: (state) => state.isuAuth,
  },
});
