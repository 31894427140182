<template>
  <div class="dropdown" @click="dropdownOpen = !dropdownOpen">
    <div class="dropbtn-setting">
      <img class="settings-icon" :src="settingsIcon" alt="" />
    </div>
    <div class="dropdown-content" v-show="dropdownOpen">
      <a
        v-for="(setting, index) in availableSetting"
        :key="index"
        :href="setting.disabled ? null : '#'"
        @click.prevent="setting.disabled || setSetting(setting.settingName)"
        :class="{ disabled: setting.disabled }"
      >
        <img class="setting-icon" :src="setting.icon" alt="" />
        <span class="setting-text">{{ setting.settingName }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import logoutIcon from "@/assets/logout.svg";
import settingsIcon from "@/assets/settings.svg";
import testingIcon from "@/assets/testing-icon.svg";
import wifiIcon from "@/assets/wifi.svg";
import lightIcon from "@/assets/lightMode.svg";

export default defineComponent({
  data() {
    return {
      availableSetting: [
        { settingName: "Logout", icon: logoutIcon, disabled: false },
        { settingName: "TestPage", icon: testingIcon, disabled: false },
        { settingName: "WIFI", icon: wifiIcon, disabled: true },
        { settingName: "Light mode", icon: lightIcon, disabled: true }, // this mode is disabled
      ],
      settingsIcon: settingsIcon,
      dropdownOpen: false,
    };
  },
  methods: {
    setSetting(newSetting) {
      this.$emit("updateSetting", newSetting);
    },
  },
});
</script>

<style>
.settings-icon {
  width: 1.2rem;
  height: 1.2rem;
}
.dropbtn-setting {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(63, 63, 63);
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem solid #858585;
}
.setting-icon {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0.5rem;
}

.setting-text {
  display: inline-block;
  font-weight: bold;
  font-size: 0.8rem;
}
</style>
