<template>
  <div class="login">
    <div class="security-code" v-if="!isError">
      <div class="code-inputs">
        <input
          v-for="(input, index) in inputs"
          :key="index"
          type="text"
          :id="`code-${index}`"
          :ref="`code-${index}`"
          v-model="input.value"
          @input="handleInput(index)"
          @keyup="handleKeyUp(index, $event)"
          maxlength="1"
          pattern="\d"
          required
          class="code-input"
          :blur="index === 5 ? handleSubmit : null"
          @keyup.enter="handleSubmit"
          inputmode="numeric"
        />
      </div>
      <label for="code">To login please enter 6 digit pin</label>
    </div>
    <div class="pincode-btn" v-if="!isError">
      <button @click="displayPinCode">display pin code</button>
    </div>
    <div class="error-message" v-else>
      <p>{{ message }}</p>
      <button @click="resolveError">ok</button>
    </div>
    <div class="isu-path-input">
      <input
        v-model="newIsuPath"
        @keyup.enter="setIsuPath(newIsuPath)"
        placeholder="Enter new ISU path"
        class="isu-path-input-field"
      />
      <p>{{ isuPathMessage }}</p>
    </div>
    <div>
      <span class="version-num">v1.0.0.31</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import myImage from "@/assets/logoWhite.svg";
import * as jose from "jose";

export default {
  name: "LoginView",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$emit("update-header", {
        title: "",
        headerImage: myImage,
      });
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$emit("update-header", {
      title: "",
      headerImage: "",
    });
    next();
  },
  data() {
    return {
      inputs: [
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
      ],
      isValid: false,
      showInputs: false,
      newIsuPath: "",
      message: "error",
      isuPathMessage: "",
      isError: false,
      appPubKey: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArxfFHUehV4dAEdf3lgJP
0bciOR+WZMDKWob9cIdyIz13Fs6yYCdfh2xqKXzZiV9sxUGJN6+zX1Rnq727cjrE
302RoL4IKI/qB3IOYgn7Bu/q6ykFhUsEj8dsVBeOoVALwcOzgxPiLCvSmq302wMf
eq1rQrNmZQlLCum1jsiuYGeYwES3tgaHQp02YJ8Yd4TWsApMmx8EUiLVVn+t7Q72
dGeTSfQSnu1OYp5cL+5X5xu+hl6YY7ZeOfwwhHfI1TeuJqJD/mGNe8yl9PZ1BNc+
XRAJQQVebt4fKM5kOmN1dBaII5r3KDLUmF6hB3rXHbHwfDl70t2SLcUCLMZXU+vR
qQIDAQAB
-----END PUBLIC KEY-----
`,
    };
  },
  computed: {
    ...mapGetters(["isuPath", "isuToken", "isuAuth"]),
  },
  methods: {
    ...mapActions(["setIsuToken", "setIsuPath"]),
    setIsuPath(newIP) {
      const newPath = `https://${newIP}`;
      this.$store.dispatch("setIsuPath", newPath);
      this.newIsuPath = ""; // Clear the input field
      this.isuPathMessage = "All Set!";
    },
    async displayPinCode() {
      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: this.isuAuth,
          },
          body: JSON.stringify({}),
        };
        const response = await fetch(`${this.isuPath}/login/otp`, options);
        if (response.ok) {
          console.log("The code should appear on the ISU screen");
        } else {
          this.isError = true;
          this.message = "The ISU response was unexpected, please try again";
        }
      } catch (error) {
        this.isError = true;
        this.message = "ISU cant be reached, check your connection";
      }
    },
    handleInput(index) {
      if (
        this.inputs[index].value.length === 1 &&
        /^\d$/.test(this.inputs[index].value)
      ) {
        if (index < 5) {
          this.$refs[`code-${index + 1}`][0].focus();
        }
        this.isValid = this.inputs.every((input) => /^\d$/.test(input.value));
      } else {
        this.inputs[index].value = "";
        this.isValid = false;
      }
    },
    handleKeyUp(index, event) {
      if (event.code === "Backspace" || event.key === "Backspace") {
        // Handle Backspace key
        if (index > 0) {
          this.$refs[`code-${index - 1}`][0].focus();
          this.inputs[index - 1].value = "";
        } else {
          this.inputs[index].value = "";
        }
        this.isValid = false;
      }
    },
    async handleSubmit() {
      if (this.isValid) {
        const securityCode = this.inputs.map((input) => input.value).join("");
        await this.verifyCode(securityCode);
      } else {
        this.message = "Please enter a valid 6-digit security code.";
      }
    },
    async verifyCode(securityCode) {
      try {
        const { v4: uuidv4 } = require("uuid");
        const guid = uuidv4();
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: this.isuAuth,
            "x-app-challange": guid,
          },
          body: JSON.stringify({ password: securityCode }),
        };
        const response = await fetch(
          `${this.isuPath}/login/otp/verify`,
          options
        );
        const data = await response.json();
        if (response.ok) {
          const appChallangeRespose = response.headers.get(
            "x-app-challenge-response"
          );
          const challangeResult = await this.validateJwtToken(
            appChallangeRespose
          );
          if (challangeResult) {
            const appToken = data["app-token"];
            this.setIsuToken(appToken);
            this.$router.push("/surgeon");
          }
        } else if (response.status === 401) {
          this.isError = true;
          this.message = "Incorrect pin code, please try again!";
        } else {
          this.isError = true;
          this.message = "Login failed, please try again";
        }
      } catch (error) {
        this.message = "Something went wrong, the ISU can't be reached";
      }
    },
    async validateJwtToken(token) {
      try {
        const publicKey = await jose.importSPKI(this.appPubKey, "RS256");
        const { payload } = await jose.jwtVerify(token, publicKey, {
          audience: "isu-pwa",
        });
        if (payload.name === "pwa-challenge") {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error("Error verifying JWT:", error);
        return false;
      }
    },
    resolveError() {
      this.isError = false;
    },
  },
};
</script>

<style>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  gap: 30px;
}
.security-code,
.error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #3f3f3f;
  border: 0.75rem solid rgba(51, 51, 51, 1);
  border-radius: 0.5rem;
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  box-sizing: border-box;
  width: 22rem;
  height: 11rem;
}
.error-message {
  background-color: #f48120;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0rem;
}

div.security-code label {
  color: white;
  display: block;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.code-inputs {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.code-input {
  display: flex;
  width: 30px;
  text-align: center;
  border: none;
  border-bottom: 2px solid #f48120;
  background-color: transparent;
  outline: none;
  color: white;
  font-size: 3.5em;
}
.pincode-btn button {
  background-color: #f48120;
  border-radius: 10rem;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pincode-btn button:hover {
  background-color: #d87018;
}

.error-message p {
  color: white;
  font-weight: bold;
}
.error-message button {
  background-color: #00457c;
  border-radius: 15px;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.error-message button:hover {
  background-color: #003366;
}
.version-num {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 2rem;
  padding-right: 2rem;
  color: white;
}
.isu-path-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}
.isu-path-input p {
  color: white;
}
.isu-path-input-field {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
